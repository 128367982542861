import { Injectable } from '@angular/core';
import {
  AuthenticationService,
  UserPersonalData,
} from '../authentication.service';
import { ALL_FEATURES_AVAILABLE, SubscriptionPlans } from '../user/user.model';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JsonResponse } from '../../shared/api/backend-config';
import { ModalService } from '../../shared/services/modal.service';
import { registrationRequiredText } from '../map-redirect-modal/map-redirect-modal-text';

export type MapLimitsStatus = {
  greenRateExceeded?: boolean;
  yellowRateExceeded?: boolean;
  redRateExceeded: boolean;
};
export type FeatureType = string;

export const FEATURES_CLICKED = 'FEATURES_CLICKED';
export const FEATURES_CLICKED_DATE = 'FEATURES_CLICKED_DATE';

// 14 days in milliseconds
const FOURTEEN_DAYS_MS = 12096e5;
const THREE_DAYS_MS = 2592e5;

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  // We need it to trigger change detection for pipes in menu toggles
  public isUserPlanUpdated: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isRedRateExceeded = new BehaviorSubject<boolean | undefined>(
    undefined
  );

  public isTrialExpired: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public isPaidPlanActive: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private userPlan: SubscriptionPlans | null = null;

  private isAdmin = false;

  private isTrial = false;

  public isUnauthorized = true;
  public isFeaturesClickLimitExceeded = false;

  public isMapLoadingRestricted = false;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private modalService: ModalService
    // private subscriptionsService: SubscriptionsService
  ) {
    authService.userPersonalData.subscribe((user) => this.setUserPlan(user!));
    this.isFeaturesClickLimitExceeded =
      Number(localStorage.getItem(FEATURES_CLICKED)) >
      SubscriptionPlans.unauthorizedUser.actionsLimit;
  }

  /**
   * @return true if current user has sufficient access to feature/component.
   * Note: frontend access checks are for UX.
   * Backend checks actual access for all the endpoints for security.
   */
  public userHasAccess(feature: FeatureType): boolean {
    if (!this.authService.hasAccessToken()) {
      const accessibleFeatures =
        SubscriptionPlans.unauthorizedUser.accessibleFeatures;
      return (
        accessibleFeatures.includes(feature) ||
        accessibleFeatures.includes(feature.toLowerCase()) ||
        accessibleFeatures === ALL_FEATURES_AVAILABLE
      );
    }

    if (this.isTrial) {
      const accessibleFeatures =
        SubscriptionPlans.trialDescription.accessibleFeatures;
      return (
        accessibleFeatures.includes(feature) ||
        accessibleFeatures.includes(feature.toLowerCase()) ||
        accessibleFeatures === ALL_FEATURES_AVAILABLE
      );
    }

    if (this.userPlan) {
      const accessibleFeatures =
        SubscriptionPlans.description[this.userPlan].accessibleFeatures;
      return (
        accessibleFeatures.includes(feature) ||
        accessibleFeatures.includes(feature.toLowerCase()) ||
        accessibleFeatures === ALL_FEATURES_AVAILABLE
      );
    }

    return false;
  }

  public setUserPlan(info: UserPersonalData | null): void {
    if (info) {
      this.isUserPlanUpdated.next(false);
      this.userPlan = info?.subscription?.plan || null;
      this.isAdmin = info?.isAdmin || false;
      this.isTrial = !this.isAdmin && this.userPlan === null;
      this.isUnauthorized = false;

      if (this.userPlan !== null) {
        this.isPaidPlanActive.next(true);
        localStorage.setItem(FEATURES_CLICKED, '0');
      }

      this.updateTrialExpiredStatus(info.createdAt);

      // if (this.isTrial && this.subscriptionsService.accessibleFeaturesList.length === 0) {
      //   this.subscriptionsService.setAccessibleFeaturesId()
      // } else if (!this.isTrial) {
      //   this.subscriptionsService.setAccessibleFeaturesAll()
      // }

      this.isUserPlanUpdated.next(true);
    } else if (info === null) {
      this.isUserPlanUpdated.next(false);

      this.userPlan = null;
      this.isAdmin = false;
      this.isTrial = false;
      this.isUnauthorized = true;
      this.isPaidPlanActive.next(false);
      this.isUserPlanUpdated.next(true);
    }
  }

  public updateTrialExpiredStatus(createdAt: string): void {
    if (this.isTrial) {
      const createdAtDate = new Date(createdAt);
      const currentTime = new Date(); // Current time

      const expirationDate = new Date(createdAtDate.getTime() + THREE_DAYS_MS); // 3 days since createdAt

      this.isTrialExpired.next(currentTime >= expirationDate);
    } else {
      this.isTrialExpired.next(false);
    }
  }

  public getUserPlan(): SubscriptionPlans | null {
    return this.userPlan;
  }

  public getIsAdmin(): boolean {
    return this.isAdmin;
  }

  public getIsUnauthorized(): boolean {
    return this.isUnauthorized;
  }

  public getIsAuthorized(): boolean {
    return !this.isUnauthorized;
  }

  public getIsTrial(): boolean {
    return this.isTrial;
  }

  /**
   * Change subscription of given user.
   * Works only for admins which is assured by backend.
   */
  public grantSubscription(
    toWhomEmail: string,
    plan: SubscriptionPlans,
    durationDays = 1_000,
    sendEmail = false
  ): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}user/subscription/grant` +
        `?existingUserEmail=${toWhomEmail}&plan=${plan}&durationDays=${durationDays}&sendEmail=${sendEmail}`,
      {}
    );
  }

  public triggerMapLoadedEvent(): void {
    this.http
      .post(`${environment.apiUrl}user/metrics/events/map-loaded`, {})
      .subscribe();
  }

  public triggerSiteOpenedEvent(): void {
    this.http
      .post(`${environment.apiUrl}user/metrics/events/site-opened`, {})
      .subscribe();
  }

  public checkMapUnauthorizedAccessibility(): void {
    this.http
      .get<JsonResponse<MapLimitsStatus>>(
        `${environment.apiUrl}user/metrics/map-limits`
      )
      .pipe(
        catchError((err) => {
          console.warn(err);
          // In case of error emit undefined value as it has to be processed later
          return of({ error: null, object: { redRateExceeded: undefined } });
        })
      )
      .subscribe((data) => {
        this.isRedRateExceeded.next(data.object.redRateExceeded);
      });
  }

  public showSignInRequiredModal(): void {
    this.modalService.openModal(registrationRequiredText);
  }

  public checkAccessIfUnauthorized(): boolean {
    if (this.isUnauthorized) {
      if (this.isFeaturesClickLimitExceeded) {
        this.showSignInRequiredModal();
        this.isMapLoadingRestricted = true;
        return false;
      } else {
        this.handleUnauthorizedClick();
      }
    }

    this.isMapLoadingRestricted = false;
    return true;
  }

  public getIsClicksLimitExceeded(): boolean {
    return this.isFeaturesClickLimitExceeded;
  }

  private handleUnauthorizedClick(): void {
    let clicksDone = Number(localStorage.getItem(FEATURES_CLICKED)) || 0;

    clicksDone += 1;

    const today = new Date().toISOString().split('T')[0];

    const lastResetDate = localStorage.getItem(FEATURES_CLICKED_DATE);

    if (lastResetDate !== today) {
      localStorage.setItem(FEATURES_CLICKED, '0');
      localStorage.setItem(FEATURES_CLICKED_DATE, today);
    }

    if (clicksDone >= SubscriptionPlans.unauthorizedUser.actionsLimit) {
      this.isFeaturesClickLimitExceeded = true;
    }

    localStorage.setItem(FEATURES_CLICKED, String(clicksDone));
  }
}
