
<div class="active-filter">

  <div class="title">
    <span>{{ filter.featureConst | LayerNameFormatPipe }}</span>
    <button app-icon-button iconSize="16px" (click)="emitRemoveFilter()" class="remove-button">
      <app-icon svgIcon="trash"></app-icon>
    </button>
  </div>

  <div *ngIf="filter.accessibleOnLevels && filter.accessibleOnLevels?.includes(activeLevel!) || !filter.accessibleOnLevels; else notAvailableOnLevel">
    <mat-slider *ngIf="isNumber(filter.max! + filter.min!)"
                [step]="filter | sliderValues:STEP"
                [min]="filter | sliderValues:MIN_LIMIT"
                [max]="filter | sliderValues:MAX_LIMIT"
                style="width: 100%;"
    >
      <input [value]="filter.min" matSliderStartThumb
             (valueChange)="updateFilter(filter, $event, true)">
      <input [value]="filter.max" matSliderEndThumb
             (valueChange)="updateFilter(filter, $event, false)">
    </mat-slider>

    <div class="slider-labels" *ngIf="filter.min && (filter.min | isValidNumber) && filter.max && filter.max | isValidNumber; else noDataAvailable">
      <input type="text"
             [value]="isMinInputFocused ? (filter.min! | formatFilterInputValue:filter.featureConst) : (filter.min! | numberFormatter:2:filter.featureConst)"
             (focus)="handleInputFocus('min')"
             (blur)="handleInputBlur('min', $event, filter.featureConst)"
             (change)="handleInputChange('min', $event, filter.featureConst)"
             (input)="handleInput($event)"
             [ngClass]="isMinInputFocused ? 'inputFocused' : ''"
             class="slider-label-input">

      <input type="text"
             [value]="isMaxInputFocused ? (filter.max! | formatFilterInputValue:filter.featureConst) : (filter.max! | numberFormatter:2:filter.featureConst)"
             (focus)="handleInputFocus('max')"
             (blur)="handleInputBlur('max', $event, filter.featureConst)"
             (change)="handleInputChange('max', $event, filter.featureConst)"
             (input)="handleInput($event)"
             [ngClass]="isMaxInputFocused ? 'inputFocused' : ''"
             class="slider-label-input">
    </div>

  </div>


</div>

<ng-template #notAvailableOnLevel>
  <span *ngIf="filter.disclaimer">{{filter.disclaimer.text}}</span>
</ng-template>

<ng-template #noDataAvailable>
  <span class="no-data-disclaimer">No data available in this area</span>
</ng-template>
