import { Injectable } from '@angular/core';
import { MapHttpService } from '../services/map-http.service';
import { FeatureFocusService } from '../../feature-focus/feature-focus.service';
import { MapBoxService } from '../mapbox.service';
import { LoadingService } from '../services/loading.service';
import { debounceTime, finalize, share } from 'rxjs';
import { JsonResponse } from '../../../shared/api/backend-config';
import { NaturalId } from '../../menu/right-menu/layers-menu/population-menu/population.service';
import { LayerStoreService } from '../services/layer-store.service';
import * as mapboxgl from 'mapbox-gl';
import { ToastrService } from 'ngx-toastr';
import {
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,
  hwwFeatures,
} from '../../../shared/types/feature-data-type';
import { convertLayerIdToCellType } from '../../../shared/util/layerIdToCellType';
import { HealthWealthWiseService } from '../../menu/right-menu/layers-menu/health-wealth-wise/health-wealth-wise.service';

export interface ByCellsJSON {
  byCells: ByCells[];
}
export type ByCells = { naturalId: string } & { [key: string]: number };
export interface FeatureMetaData {
  id: any;
  source: string;
  sourceLayer: string;
}

@Injectable({
  providedIn: 'root',
})
export class MapColoringService {
  private readonly naturalIds: NaturalId[] = [];

  private isColoringMapErrorToastBeingShown = false;

  constructor(
    private http: MapHttpService,
    private featureFocusService: FeatureFocusService,
    private mapboxService: MapBoxService,
    private loadingService: LoadingService,
    private layerStore: LayerStoreService,
    private hwwService: HealthWealthWiseService,
    private toast: ToastrService
  ) {}

  public colorMapByCells(layer: string): void {
    const map = this.mapboxService.map;
    this.loadingService.isLoadingManual.next(true);

    this.naturalIds.length = 0;

    const naturalIdToFeatureMap: Map<string, FeatureMetaData> = new Map();

    const formattedLayer = this.preformatFeatureName(layer);

    const currentFocusedFeatures = this.featureFocusService
      .currentFocusedFeatures()
      .getFeatures();
    currentFocusedFeatures.forEach((feature) => {
      const featureMetaData: FeatureMetaData = {
        id: feature.id,
        source: feature.source,
        sourceLayer: feature.sourceLayer,
      };

      if (!feature.properties!.external_id) {
        return;
      }

      const state = map.getFeatureState(featureMetaData);

      if (
        !state.hasOwnProperty(formattedLayer) &&
        !state[`${formattedLayer}Requested`]
      ) {
        this.naturalIds.push(feature.properties!.external_id);
        naturalIdToFeatureMap.set(
          feature.properties!.external_id,
          featureMetaData
        );
        map.setFeatureState(featureMetaData, {
          [`${formattedLayer}Requested`]: true,
        });
      }
    });

    if (!this.naturalIds.length) {
      this.loadingService.isLoadingManual.next(false);
      return;
    }

    // Skip request if we're not going to color map, write null to state
    if (
      this.layerStore.isLayerLevelCombinationUnavailable(
        currentFocusedFeatures[0].layer.id!,
        layer
      )
    ) {
      naturalIdToFeatureMap.forEach((feature) => {
        map.setFeatureState(feature, { [formattedLayer]: null });
      });
      return;
    }

    this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, true);
    this.featureFocusService.focusRenderedFeatures(
      map,
      this.layerStore.activeLevel.getValue()
    );

    // Use level id of first feature in array of focused features
    this.http
      .getBulkMapColoringData(
        this.naturalIds,
        formattedLayer,
        convertLayerIdToCellType(currentFocusedFeatures[0].layer.id)!
      )
      .pipe(
        debounceTime(150),
        share(),
        finalize(() => {
          this.loadingService.isLoadingManual.next(false);
          this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, false);
          this.featureFocusService.focusRenderedFeatures(
            map,
            this.layerStore.activeLevel.getValue()
          );
        })
      )
      .subscribe(
        (data: JsonResponse<ByCellsJSON> | null) => {
          if (!data || !data.object) return;

          if (hwwFeatures.includes(formattedLayer)) {
            this.hwwService.handleHwwFeatures(
              data.object,
              naturalIdToFeatureMap,
              formattedLayer
            );
            return;
          }

          data.object.byCells.forEach((cell: ByCells) => {
            const correspondingFeature = naturalIdToFeatureMap.get(
              cell.naturalId
            );
            if (correspondingFeature) {
              let value;
              if (
                isNaN(cell['value']) ||
                cell['value'] === null ||
                cell['value'] === undefined
              ) {
                value = null;
              } else if (
                formattedLayer === BIDEN_VS_TRUMP_2020_POPULATION ||
                formattedLayer === HARRIS_VS_TRUMP_2024_POPULATION
              ) {
                value = -cell['value'];
              } else {
                value = cell['value'];
              }

              const newState = { [formattedLayer]: value };
              map.setFeatureState(correspondingFeature, newState);
            }
          });
        },
        (error) => {
          if (!this.isColoringMapErrorToastBeingShown) {
            this.toast
              .error(
                'There was an error while coloring the map, please try later'
              )
              .onHidden.subscribe(() =>
                setTimeout(
                  () => (this.isColoringMapErrorToastBeingShown = false),
                  15_000
                )
              );
          }
          this.isColoringMapErrorToastBeingShown = true;
          this.naturalIds.forEach((naturalId) => {
            const featureMetaData = naturalIdToFeatureMap.get(naturalId);
            if (featureMetaData) {
              map.setFeatureState(featureMetaData, {
                [`${formattedLayer}Requested`]: false,
              });
            }
          });
        }
      );
  }

  private preformatFeatureName(feature: string): string {
    let formattedName = feature;

    if (hwwFeatures.includes(feature)) {
      formattedName = feature.replace(/_BY_[A-Z]+/i, '');
    }

    return formattedName;
  }

  /**
   * Mark the feature that it's being loaded.
   * Must be set false when loading is finished either by data loaded either but error
   */
  private markAsBeingLoaded(
    cells: IterableIterator<FeatureMetaData>,
    map: mapboxgl.Map,
    isBeingLoaded = true
  ) {
    for (let cell of cells) {
      map.setFeatureState(cell, { isBeingLoaded: isBeingLoaded });
    }
  }
}
